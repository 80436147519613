import { Fragment, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
    requireAuth,
    useQuery,
    useAuthState,
    getWorkflowWaitTimeInSeconds,
} from 'packages/core'
import { Loader } from 'packages/eid-ui'
import withRequiredData from './withRequiredData'
import { PageContextProvider } from 'pageContext'
import Workflows from 'containers/Workflows'
import { AppLayout } from 'components'
import { setIsWorkflowRenderable } from 'packages/core/auth/actions'
import config from 'config'
import { RequestViewAllApplications } from 'containers/Applications'
import { RequestViewAllGroups } from 'containers/Groups'
import { RequestViewAllManagementRoles } from 'containers/Management Roles'
import { RequestViewAllSharedFolders } from 'containers/Shared Folders'
import useSubcomponents from 'useSubcomponents'
import { useRouteOptions } from 'hooks'
import withCustomTheme from './withCustomTheme'

import { useAppState } from 'appContext'
import { RequestViewAllMailboxes } from 'containers/Mailboxes'
import { RequestViewAllPeople } from 'containers/People'

const App = () => {
    const [{ isWorkflowIRenderable }, authDispatch] = useAuthState()
    const [_, dispatch] = useAppState()
    const query = useQuery()
    const workflowName = query.get('workflowName')
    const workflowWaitTime = getWorkflowWaitTimeInSeconds() * 1000

    useEffect(() => {
        if (!isWorkflowIRenderable) {
            setTimeout(() => {
                authDispatch(setIsWorkflowRenderable(true))
            }, workflowWaitTime)
        }
    }, [])

    useEffect(() => {
        if (config.ENABLE_DEV_FEATURES?.toString().toLowerCase() === 'true') {
            dispatch({
                type: 'ENABLE_DEV_FEATURES',
            })
        }
        if (query.get('enableDevFeatures')) {
            query.delete('enableDevFeatures')
            dispatch({
                type: 'ENABLE_DEV_FEATURES',
            })
        }
    }, [])

    const { trimPagesBasedOnAccess } = useSubcomponents()

    const { routeOptions } = useRouteOptions()
    const allowedRoutes = trimPagesBasedOnAccess(routeOptions)
    const defaultRoute =
        allowedRoutes && allowedRoutes.length > 0
            ? allowedRoutes[0].route
            : null
    const hasAccessToViewApplications = allowedRoutes.find(
        (f) => f.name === 'Applications',
    )
    const hasAccessToViewGroups = allowedRoutes.find((f) => f.name === 'Groups')
    const hasAccessToViewManagementRoles = allowedRoutes.find(
        (f) => f.name === 'Management Roles',
    )
    const hasAccessToViewSharedFolders = allowedRoutes.find(
        (f) => f.name === 'Shared Folders',
    )

    const hasAccessToViewMailBoxes = allowedRoutes.find(
        (f) => f.name === 'Mailboxes',
    )
    const hasAccessToViewPeople = allowedRoutes.find((f) => f.name === 'People')

    return (
        <Fragment>
            {!isWorkflowIRenderable && (
                <div style={{ display: 'none' }}>
                    <iframe
                        title={`EmpowerID`}
                        src={`${config.BASE_EID_URL}/UI/Common/SAML2/HiddenBlankPage`}
                    />
                </div>
            )}
            <Switch>
                {hasAccessToViewApplications && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/applications/workflows`}
                        render={(props) => (
                            <PageContextProvider key="applications/workflows">
                                <AppLayout
                                    showNavTabs
                                    showBackButton={workflowName}
                                    backButtonLabel={'BackToWorkflow'}
                                >
                                    <Workflows
                                        noun={'ResourceAdmin'}
                                        verb={'Application'}
                                    />
                                </AppLayout>
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToViewGroups && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/groups/workflows`}
                        render={(props) => (
                            <PageContextProvider key="groups/workflows">
                                <AppLayout
                                    showNavTabs
                                    showBackButton={workflowName}
                                    backButtonLabel={'BackToWorkflow'}
                                >
                                    <Workflows
                                        noun={'ResourceAdmin'}
                                        verb={'Group'}
                                    />
                                </AppLayout>
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToViewManagementRoles && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/managementRoles/workflows`}
                        render={(props) => (
                            <PageContextProvider key="managementRoles/workflows">
                                <AppLayout
                                    showNavTabs
                                    showBackButton={workflowName}
                                    backButtonLabel={'BackToWorkflow'}
                                >
                                    <Workflows
                                        noun={'ResourceAdmin'}
                                        verb={'ManagementRole'}
                                    />
                                </AppLayout>
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToViewSharedFolders && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/sharedFolders/workflows`}
                        render={(props) => (
                            <PageContextProvider key="sharedFolders/workflows">
                                <AppLayout
                                    showNavTabs
                                    showBackButton={workflowName}
                                    backButtonLabel={'BackToWorkflow'}
                                >
                                    <Workflows
                                        noun={'ResourceAdmin'}
                                        verb={'SharedFolder'}
                                    />
                                </AppLayout>
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToViewPeople && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/people/workflows`}
                        render={(props) => (
                            <PageContextProvider key="people/workflows">
                                <AppLayout
                                    showNavTabs
                                    showBackButton={workflowName}
                                    backButtonLabel={'BackToWorkflow'}
                                >
                                    <Workflows
                                        noun={'ResourceAdmin'}
                                        verb={'People'}
                                    />
                                </AppLayout>
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToViewMailBoxes && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/mailboxes/workflows`}
                        render={(props) => (
                            <PageContextProvider key="mailboxes/workflows">
                                <AppLayout
                                    showNavTabs
                                    showBackButton={workflowName}
                                    backButtonLabel={'BackToWorkflow'}
                                >
                                    <Workflows
                                        noun={'ResourceAdmin'}
                                        verb={'Mailbox'}
                                    />
                                </AppLayout>
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToViewApplications && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/claimsMappingPolicy/workflows`}
                        render={(props) => (
                            <PageContextProvider key="claimsMappingPolicy/workflows">
                                <AppLayout
                                    showNavTabs
                                    showBackButton={workflowName}
                                    backButtonLabel={'BackToWorkflow'}
                                >
                                    <Workflows
                                        noun={'ResourceAdmin'}
                                        verb={'ClaimsMappingPolicy'}
                                    />
                                </AppLayout>
                            </PageContextProvider>
                        )}
                    />
                )}

                {hasAccessToViewApplications && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/applications/:tab?`}
                        render={(props) => (
                            <PageContextProvider key="RequestViewApplications">
                                <RequestViewAllApplications {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToViewGroups && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/groups`}
                        render={(props) => (
                            <PageContextProvider key="RequestViewAllGroups">
                                <RequestViewAllGroups {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToViewManagementRoles && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/managementRoles`}
                        render={(props) => (
                            <PageContextProvider key="RequestViewAllManagementRoles">
                                <RequestViewAllManagementRoles {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}

                {hasAccessToViewSharedFolders && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/sharedFolders`}
                        render={(props) => (
                            <PageContextProvider key="RequestViewAllSharedFolders">
                                <RequestViewAllSharedFolders {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}
                {hasAccessToViewPeople && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/people`}
                        render={(props) => (
                            <PageContextProvider key="RequestViewAllPeople">
                                <RequestViewAllPeople {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}

                {hasAccessToViewMailBoxes && (
                    <Route
                        exact
                        path={`${config.APP_SUBPATH}/mailboxes`}
                        render={(props) => (
                            <PageContextProvider key="RequestViewAllMailboxes">
                                <RequestViewAllMailboxes {...props} />
                            </PageContextProvider>
                        )}
                    />
                )}

                <Route exact path={`${config.APP_SUBPATH}/`}>
                    {defaultRoute ? (
                        <Redirect to={`${config.APP_SUBPATH}${defaultRoute}`} />
                    ) : (
                        <Redirect
                            from=""
                            to={`${config.APP_SUBPATH}/notfound`}
                        />
                    )}
                </Route>
                <Redirect from="" to={`${config.APP_SUBPATH}/notfound`} />
            </Switch>
        </Fragment>
    )
}

export default requireAuth(withRequiredData(withCustomTheme(App)), <Loader />)
