import { Box, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import { Avatar, Tooltip } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl, imagesDisabled } from 'utils'

const useStyles = makeStyles(() => ({
    selectPeopleArea: {
        background: '#fff !important',
        padding: '8px',
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'space-between',
        margin: '0px 0px 8px 0px',
        borderRadius: '4px',
        minHeight: '40px',
        scrollbarWidth: 'none',
        '&:hover': {
            background: '#efeff1 !important',
            '& .MuiBox-root': {
                background: '#efeff1 !important',
            },
        },
        '& .MuiBox-root': {
            background: '#fff !important',
            maxWidth: 'fit-content !important',
        },

        '& span': {
            fontSize: '12px',
            color: '#5d6870',
            width: '175px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'block',
        },
        '& h2': {
            fontSize: '14px !important',
            color: '#5d6870 !important',
            fontWeight: '600',
            width: '190px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            userSelect: 'none',
        },
        '& svg': {
            width: '16px',
            height: '16px',
        },
    },
    selectedPeopleArea: {
        background: 'rgb(48, 127, 193) !important',
        lineHeight: 1,

        '&:hover': {
            background: 'rgb(48, 127, 193) !important',
            '& .MuiBox-root': {
                background: 'rgb(48, 127, 193) !important',
            },
        },
        '& .MuiBox-root': {
            background: 'rgb(48, 127, 193) !important',
        },
        '& h2': {
            color: '#fff !important',
        },
        '& span': {
            color: '#fff !important',
        },
    },
    dimOpacity: {
        opacity: '0.75',
    },
    avtar: {
        '& .MuiAvatar-circle': {
            width: '16px !important',
            height: '16px !important',
            borderRadius: '50%',
            marginRight: '8px',
        },
    },
    disableMember: {
        background: '#e4e4ec !important',
        cursor: 'default',
        '& .MuiBox-root': {
            background: '#e4e4ec !important',
        },
        '& h2': {
            color: '#5d68708f !important',
        },
        '&:hover': {
            background: '#e4e4ec !important',
            '& .MuiBox-root': {
                background: '#e4e4ec !important',
            },
        },
    },
}))

const MemberIAMShopAssigment = ({
    isSelectedAttribute,
    isLastSelectedItem,
    isAlreadyAddedToCart,
    item,
    index,
    handleSelectPeople,
    isUnAssigned = false,
    handleDoubleClick = () => {
        return
    },
    isLeftSide = false,
    isRightSide = false,
    secondaryAttributes,
    isInherited = false,
}) => {
    const classes = useStyles({})
    const { t } = useTranslation()

    const bindSecondaryText = () => {
        let secondaryText = ''

        secondaryAttributes.forEach((element) => {
            if (item[element]) {
                if (element === 'startDateUtc' || element === 'endDateUtc') {
                    let time = moment
                        .utc(item[element])
                        .clone()
                        .local()
                        .format('lll')
                    secondaryText = secondaryText
                        ? `${secondaryText} - ${time}`
                        : time
                } else {
                    secondaryText = secondaryText
                        ? `${secondaryText} - ${item[element]}`
                        : item[element]
                }
            }
        })
        return secondaryText
    }

    const bindSecondaryAttributes = () => {
        let secondaryText = bindSecondaryText()

        let secondaryComponent = (
            <Box display="flex">
                {secondaryAttributes.some(
                    (x) => x === 'startDateUtc' || x === 'endDateUtc',
                ) &&
                    (item['startDateUtc'] || item['endDateUtc']) && (
                        <Box width="16px" style={{ marginRight: '8px' }}>
                            <Icon
                                name="Clock"
                                color={
                                    isSelectedAttribute.isSelected
                                        ? '#fff'
                                        : '#5d6870'
                                }
                            />
                        </Box>
                    )}
                <Typography variant="p">{secondaryText}</Typography>
            </Box>
        )
        return secondaryComponent
    }

    const getTooltip = () => {
        if (isInherited) {
            return t('InheritedAssignmentMessage')
        }
        if (isAlreadyAddedToCart) {
            return t('Common_ItemAlreadyInCart')
        }
    }
    return (
        <Box
            style={{ userSelect: 'none' }}
            className={classNames(classes.selectPeopleArea, {
                [classes.selectedPeopleArea]: isSelectedAttribute?.isSelected,
                [classes.dimOpacity]:
                    isLeftSide &&
                    isSelectedAttribute?.isSelected &&
                    !isLastSelectedItem,
                [classes.disableMember]: isAlreadyAddedToCart || isInherited,
            })}
            key={`search_item-${index}`}
            onClick={() => handleSelectPeople(item)}
            onDoubleClick={(e) => {
                handleDoubleClick(e, item)
            }}
        >
            <Box display="flex" className={classes.avtar}>
                {item?.hasOwnProperty('imageThumbUrl') && !imagesDisabled && (
                    <Avatar
                        size={'small'}
                        src={getFullImageUrl(item?.imageThumbUrl)}
                    />
                )}
                <Box>
                    <Tooltip
                        title={
                            <>
                                {isAlreadyAddedToCart || isInherited ? (
                                    getTooltip()
                                ) : (
                                    <>
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {item?.friendlyName}
                                        </Typography>
                                        {bindSecondaryText() && (
                                            <Typography
                                                style={{
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {bindSecondaryText()}
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </>
                        }
                    >
                        <Box>
                            <Typography variant="h2">
                                {item?.friendlyName}
                            </Typography>
                            {bindSecondaryAttributes()
                                ? bindSecondaryAttributes()
                                : null}
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
            <Box>
                {(isUnAssigned &&
                    isLeftSide &&
                    !isSelectedAttribute?.isSelected) ||
                (isRightSide &&
                    isUnAssigned &&
                    isSelectedAttribute?.isSelected) ? (
                    <>
                        <Icon
                            name="SimpleUncheck"
                            color={'red'}
                            style={{
                                opacity:
                                    isSelectedAttribute?.isSelected &&
                                    isRightSide
                                        ? 0.8
                                        : 1,
                            }}
                        />
                    </>
                ) : (
                    <>
                        {isSelectedAttribute?.alreadyAdded && (
                            <Icon
                                name="Check"
                                color={
                                    isSelectedAttribute?.alreadyAdded
                                        ? '#8b909a'
                                        : '#fff'
                                }
                            />
                        )}
                        {isRightSide && !isUnAssigned && (
                            <Icon
                                name="Plus"
                                style={{ width: '20px', height: '20px' }}
                                color={'green'}
                            />
                        )}
                        {isLeftSide && isLastSelectedItem && (
                            <Box
                                style={{
                                    height: '100%',
                                    alignContent: 'end',
                                }}
                            >
                                <Icon
                                    name="ArrowLong"
                                    style={{
                                        width: '1rem',
                                        height: '1rem',
                                    }}
                                    color={'#fff'}
                                    direction={'right'}
                                />
                            </Box>
                        )}
                        {isLeftSide &&
                            isSelectedAttribute?.isSelected &&
                            !isLastSelectedItem && (
                                <Box
                                    style={{
                                        height: '100%',
                                        alignContent: 'end',
                                    }}
                                >
                                    <Icon name="Check" color={'#fff'} />
                                </Box>
                            )}
                    </>
                )}
            </Box>
        </Box>
    )
}

export default MemberIAMShopAssigment
